import React from "react";
import {
  Navbar,
  Nav,
  Container,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap";
import "./Navigation.css";
export default function Navigation() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/Search">
          <img src="../AB3.png" style={{ width: 70, marginTop: -7 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">About</Nav.Link>
            <Nav.Link href="/Search">Search</Nav.Link>
            <Nav.Link
              href="http://commons.docking.org/reactions"
              target="_blank"
            >
              Reactions
            </Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
