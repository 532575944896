import React from 'react';
import {Col, Row, Container} from "react-bootstrap"



function Contact() {
    return (
    <div>
    <Container>
        
        <p><b>Contact:</b><br></br>
        Please contact "jjiteam at googlegroups dot com" or "max.tsukanov at ucsf dot edu" for any help, suggestions, or converstation regarding the Analog by Building Block tool.<br></br>
        You can also vist the tool's info page here!</p>
    
        <p><b>Special Acknowledgments:</b><br></br>
         We would like to thank NIGMS GM71896 for helping support this project.<br></br>
         UC Regents<br></br>
        Irwin Lab and Shoichet Lab members.</p>
        <p><b>Software Acknowledgments:</b><br></br>
        Peter Ertl and Bruno Bienfait for the JSME editor.<br></br>
        Rdkit
        </p>
    
    </Container>
    </div>    
    );
  }
  
  export default Contact;
  