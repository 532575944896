import React from 'react';
import {Col, Row, Container} from "react-bootstrap"



function Info() {
    return (
    <div>
    <Container>
        
    <center>
    <h1><b>Welcome User</b></h1>
    </center>
    <p>Welcome to AB3, a program to find analogs accessible via one step synthesis.
AB3, or "<b>A</b>nalog <b>B</b>y <b>B</b>uilding <b>B</b>lock" uses
catalogs of commercially available compounds and two kinds of
reactions to search for one step synthetic access to compounds.</p>

<p>One method is via reliable, standard reactions. These have been
compiled from the literature, including Hartenfeller 2011, and
elsewhere.  The second source of reactions is new bespoke libraries
that have been described.</p>

<p>Both types of reaction are curated in the <a href='https://commons.docking.org' target="_blank"> Chemistry Commons</a>,
commons.docking.org.  You can create your own reactions in the Chemistry Commons. Give it a try!</p>

<p>The output of AB3 is building blocks with a commercial source, and
information about the reaction to be run.  It is up to you to get the
compounds made, either via collaboration, from a commercial vendor, or
do it yourself.</p>

<p>CAVEAT EMPTOR! AB3 is free to use and is made available in the hope
that it will be useful .  We make no guarantees about the likely
success of each reaction.  If you use AB3, you must use it at your own
risk!</p>

<p>AB3 was created at the University of California San Franciso by Max
DuNova, Khanh Tang, Mar Castanon, Andrii Kyrylchuk, JJ Guttierez
and John Irwin.  It is hosted in the Irwin and Shoichet labs at UCSF
and is supported by NIGMS GM71896 and GM133836.</p>


    </Container>
    </div>    
    );
  }
  
  export default Info;