
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Contact from './Contact';
import Info from './Info';
import reportWebVitals from './reportWebVitals';
import Navigation from './Navigation';
import Footer from './Footer';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter(
[
  { 
    path: '/Search',
    element: <App />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/',
    element: <Info/>,
  },

]);
  


ReactDOM.createRoot(document.getElementById('root')).render(
  <div>
    <Navigation />
    <RouterProvider router={router} />
    <Footer />
  </div>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
