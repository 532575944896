import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted fixed-bottom"
    >
      <div
        className="text-center p-1"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }}
      >
        Made by Maksim DuNova © Irwin Lab, 2024. Supported by NIGMS GM71896.
      </div>
    </MDBFooter>
  );
}
